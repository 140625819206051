import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"

const AssetLifeCycleManagement = () => {
    return (
        <Layout>
            <Seo title="Asset Life Cycle Management" />
            <div className="container py-3 py-lg-4">
                <div className="row g-0 mb-3">
                    <div className="col border-bottom border-1 border-dark">
                        <h1>Asset Life Cycle Management</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="card mb-3 border-0">
                        <div className="row gx-3">
                            <div className="col-lg-6">
                                <div className="card-body gray-bg d-flex flex-column">
                                    <p className="card-text">
                                        Demand for solutions that help companies optimise their operations by increasing asset uptime
                                        and ensuring long-term visibility of maintenance costs is growing in increasingly competitive
                                        markets.
                                    </p>
                                    <p>
                                        Demand for solutions that help companies optimise their operations by increasing asset uptime
                                        and ensuring long-term visibility of maintenance costs is growing in increasingly competitive
                                        markets.
                                    </p>
                                    <p>
                                        Preventive maintenance is vital to ensure smooth day-to-day operations and cost effectiveness,
                                        while high-quality reactive maintenance enables quick repairs and therefore reduces unplanned
                                        downtime.
                                    </p>
                                    <p>
                                        Introducing condition monitoring tools will provide a right on time approach to maintenance
                                        activities thus bringing additional efficiency to the whole process.
                                    </p>
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item gray-bg">
                                            Transparency: long term visibility of lifecycle maintenance costs.
                                        </li>
                                        <li className="list-group-item gray-bg">Flexibility: tailored solutions.</li>
                                        <li className="list-group-item gray-bg">
                                            Technical expertise: ensuring quality and performance.
                                        </li>
                                        <li className="list-group-item gray-bg">
                                            Innovation: introducing new products and services that maximise uptime and reduce operating
                                            costs.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <StaticImage
                                    transformOptions={{ cropFocus: "attention" }}
                                    alt="Sales team"
                                    src="../../../images/other/container4.jpg"
                                    aspectRatio={16 / 9}
                                    width={696}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default AssetLifeCycleManagement
